<template>
    <div>
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
            <el-form-item label="名称：" prop="name">
                <el-input v-model.trim="ruleForm.name" placeholder="请输入名称" clearable maxlength="10" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="企微人员：" prop="wecom_user">
                <el-input v-model.trim="ruleForm.wecom_user" placeholder="请输入企微人员" clearable maxlength="10" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="企微二维码链接：" prop="wecom_url">
                <el-input v-model.trim="ruleForm.wecom_url" placeholder="请输入企微二维码链接" clearable maxlength="200" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="描述：" prop="remark">
                <el-input v-model="ruleForm.remark" type="textarea" maxlength="500" show-word-limit :autosize="{ minRows: 4 }" placeholder="请输入描述" clearable />
            </el-form-item>
            <el-form-item label="绑定地区：" prop="bind_info">
                <div style="width: 100%">
                    <ModelCity
                        :value="ruleForm.bind_info"
                        :props="{ multiple: true, checkStrictly: true, emitPath: false }"
                        :placeholder="'请绑定地区'"
                        :collapsetags="false"
                        :filterable="true"
                        @changecity="changecityEvent"
                    />
                </div>
            </el-form-item>
            <el-form-item label="状态：" prop="status">
                <div>
                    <div>
                        <el-radio-group v-model="ruleForm.status">
                            <el-radio label="1">开启</el-radio>
                            <el-radio label="-1">关闭</el-radio>
                        </el-radio-group>
                    </div>
                    <div style="line-height: 24px; color: #666666">
                        <div><span>开启：扫设备码，跳转加企微。</span></div>
                        <div><span>关闭：关闭该设置的规则。</span></div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleFormRef')" :disabled="disabled_btn" style="width: 200px">确认 </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import ModelCity from './ModelCity.vue'
import { http } from '@/assets/js'
import { ElMessage } from 'element-plus'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AllowableAddEdit',
    props: {
        flag: { type: String, default: '' },
        form: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        form: {
            handler(newVal) {
                this.ruleForm = newVal
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            ruleForm: {
                name: '',
                wecom: '',
                wecom_user: '',
                wecom_url: '',
                remark: '',
                bind_info: '',
                status: '-1'
            }, //form表单

            rules: {
                name: [
                    {
                        required: true,
                        message: '名称为必填项',
                        trigger: 'change'
                    }
                ],
                wecom_user: [
                    {
                        required: true,
                        message: '企微人员为必填项',
                        trigger: 'change'
                    }
                ],
                wecom_url: [
                    {
                        required: true,
                        message: '企微二维码链接为必填项',
                        trigger: 'change'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '描述为必填项',
                        trigger: 'change'
                    }
                ],
                bind_info: [
                    {
                        required: true,
                        message: '当前字段为必选项',
                        trigger: 'change'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必选项',
                        trigger: 'change'
                    }
                ]
            }, //form表单-必填规则

            disabled_btn: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },

    methods: {
        // 选中地区
        changecityEvent(data) {
            this.ruleForm.bind_info = data
        },
        //from表单-提交表单
        submitForm(formName) {
            console.log('this.$refs[formName]:', this.$refs[formName])
            var getdata = this.ruleForm
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    console.log('getdata:', getdata)
                    this.disabled_btn = true
                    let data_info = {
                        name: getdata.name,
                        wecom: getdata.wecom,
                        wecom_user: getdata.wecom_user,
                        wecom_url: getdata.wecom_url,
                        remark: getdata.remark,
                        bind_info: getdata.bind_info.join(','),
                        status: getdata.status
                    }

                    if (this.$props.flag == 'edit') {
                        data_info['id'] = this.ruleForm.id
                    }

                    await http.post('/admin/Service/wecomEdit', data_info, { sMsg: true })

                    this.$emit('close-drawer', true)
                    this.$refs[formName].resetFields()
                    this.disabled_btn = false
                } else {
                    return false
                }
            })
        }
    },
    components: {
        ModelCity
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
