<template>
    <div>
        <aw-tabler :table="table">
            <template #filter>
                <el-form ref="formRef" :model="table.filter" :inline="true">
                    <el-form-item label="名称:">
                        <el-input v-model="table.filter.name" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="状态:">
                        <el-select v-model="table.filter.status" placeholder="请选择">
                            <el-option v-for="(item, index) in dict.allowable.allowable_status" :key="index" :label="item" :value="index" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </template>
            <template #drawercontent>
                <AllowableAddEdit
                    :flag="table.drawer.flex"
                    :form="table.drawer.form"
                    @close-drawer="
                        () => {
                            table.drawer.show = false
                            table.page.currentChange(1)
                        }
                    "
                />
            </template>
        </aw-tabler>
    </div>
</template>
<script setup lang="jsx">
import { http, util, dict, tool } from '@/assets/js'
import { reactive, ref, h } from 'vue'
import { useRoute } from 'vue-router'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import { ElMessage, ElMessageBox } from 'element-plus'
import AllowableAddEdit from './AllowableAddEdit.vue'

const route = useRoute()
// 当前页面操作权限集
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.role, 'component'))

const table = reactive({
    title: '用户加企微',
    select: false,
    loading: false,
    filter: {
        name: '',
        status: ''
    },
    datas: [],
    columns: [
        ...tableColumn([
            {
                title: 'ID',
                key: 'id',
                minWidth: '80px'
            },
            {
                title: '名称',
                key: 'name',
                minWidth: '100px'
            },
            {
                title: '企微人员',
                key: 'wecom_user',
                minWidth: '100px'
            },
            {
                title: '企微二维码',
                key: 'wecom_url',
                minWidth: '100px',
                render: (data) => {
                    return (
                        <span
                            class='c-blue c-p'
                            onClick={() => {
                                ElMessageBox({
                                    title: '查看企微二维码',
                                    message: h('img', {
                                        src: data.wecom_url,
                                        style: 'width: 100%; display: inline-block; position: relative; z-index: 9999;'
                                    }),
                                    showCancelButton: false,
                                    showConfirmButton: false
                                })
                            }}
                        >
                            {data.wecom_url}
                        </span>
                    )
                }
            },
            {
                title: '描述',
                key: 'remark',
                minWidth: '100px'
            },
            {
                title: '绑定地区',
                key: 'area_code_name',
                minWidth: '100px'
            },
            {
                title: '状态',
                key: 'status',
                minWidth: '80px',
                props: (data) => ({ disabled: !powerlimits.includes('yhjqw_qy') }),
                confirm: true,
                switch: ({ id, status }) => http.post('/admin/Service/wecomSwitch', { id: id, status: status == 1 ? -1 : 1 })
            },
            {
                title: '创建人',
                key: 'admin_id_name',
                minWidth: '100px'
            },
            { title: '创建时间', key: 'addtime', minWidth: '150px', time: true }
        ]),
        {
            fixed: 'right',
            title: '操作',
            key: 'operate',
            minWidth: '80px',
            buttons: [
                {
                    name: '编辑',
                    props: { type: 'primary', size: 'small' },
                    show: () => powerlimits.includes('yhjqw_bj'),
                    action: (data) => {
                        table.drawer.show = true
                        table.drawer.title = '编辑'

                        table.drawer.flex = 'edit'
                        table.drawer.form = {
                            id: data.id,
                            name: data.name,
                            wecom_user: data.wecom_user,
                            wecom_url: data.wecom_url,
                            remark: data.remark,
                            bind_info: data.bind_info?.split(','),
                            status: data.status.toString()
                        }
                    }
                }
            ]
        }
    ],
    page: {
        total: 0,
        page: 1,
        page_size: 20,
        sizeChange: (val) => {
            table.page.page_size = val
            table.action(table.filter)
        },
        currentChange: (val) => {
            table.page.page = val
            table.action(table.filter)
        }
    },
    action: async (params) => {
        if (powerlimits.includes('yhjqw_lb')) {
            table.loading = true
            let page = { page: table.page.page, page_size: table.page.page_size }
            let result = await http.get('/admin/Service/wecomList', { params: { ...params, ...route.query, ...page } })
            table.datas = result.data
            table.page.total = result.total || Infinity
            table.page.page = result.current_page
            table.page.page_size = result.per_page
            table.loading = false
        } else {
            table.datas = []
            table.page.total = Infinity
        }
        return
    },
    extras: [
        {
            name: '添加',
            props: { type: 'primary', plain: true },
            show: () => powerlimits.includes('yhjqw_tj'),
            action: () => {
                table.drawer.show = true
                table.drawer.title = '添加'

                table.drawer.flex = 'add'
                table.drawer.form = {
                    name: '',
                    wecom_user: '',
                    wecom_url: '',
                    remark: '',
                    bind_info: '',
                    status: '1'
                }
            }
        }
    ],
    drawer: reactive({
        flex: null,
        form: {},
        show: false,
        title: '',
        close: () => {
            table.drawer.show = false
        }
    })
})
</script>

<style lang="scss" scoped>
.box-item {
    width: 200px;
}
</style>
